import React from 'react'
import { Link } from 'gatsby'

import logo from '../assets/images/logoHeader_red.png';

const Header = (props) => (
    <Link to="/">
    <header id="header" className="alt">
        <span className="logo"><img src={logo} alt="" /></span>
    </header>
    </Link>
)

export default Header
