import React from 'react'
import { Gallery } from 'gatsby-theme-gallery';
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Header from '../components/Header';

class Galleria extends React.Component {
    render() {
        return (
            <Layout>
                <Header />
                <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>I Nostri Lavori</h2>
                </header>
                <p>
                  Qui di seguito un breve estratto di alcune recenti realizzazioni eseguite per conto di Nostri Clienti.
                </p>
              </div>
            </div>
                <Gallery />
                </section>
                <section className="main special">
          <Link to="/" className="button" style={{margin: "0 auto"}}>
                    Torna alla Home
        </Link>
        </section>
        </div>
            </Layout>
        )
}};

export default Galleria;